(function($){
	$(function() {

		REINOS_MAPS.ready(function(){
			var map_id = 'reinos_map_1';
			var markers =  REINOS_MAPS.markers[map_id].slice(0);
			var map =  REINOS_MAPS._map_['#'+map_id];

			var searchMarker = function(marker_name) {
				var _markers = [];

				//loop over the markers
				_.each(markers, function(val, key) {
					//search the array
					if(val['keys'] !== undefined) {
						if(jQuery.inArray(marker_name, val['keys']) !== -1) {
							_markers.push(markers[key]['marker']);
						}
					}
				});

				return _markers;
			};

			console.log(map);
			console.log(markers);
			console.log();
			//open a submenu
			$(".maps-container .data-collapse").on("click", function () {
				$(this).attr("data-collapse", $(this).attr("data-collapse") == "true" ? "false" : "true");
			});

			//toggle the filters
			$(".maps-container .maps-filters-toggle").on("click", function () {
				$(this).parents(".maps-container").attr("data-show-filters", $(this).parents(".maps-container").attr("data-show-filters") == "true" ? "false" : "true");

				//resize/refresh the map
				setTimeout(function(){
					REINOS_MAPS.api('refresh', {mapID: map_id});
					REINOS_MAPS.api('fitMap', {mapID: map_id, type: 'markers'});
				}, 300);
			}).trigger('click');

			//click a checkbox
			$(".maps-container [data-select]").on("click", function () {
				$(this).attr("data-select", $(this).attr("data-select") == "true" ? "false" : "true");

				var cat_ids =[];

				$('.categorie-link [data-select="true"]').each(function(){
					cat_ids.push($(this).data('category'));
				});

				console.log(cat_ids);

				//hide the markers for this category
				if($(this).attr("data-select") == "true") {
					map.marker_cluster.markers.clearLayers();

					var selected_markers = searchMarker(cat_id);
					_.each(selected_markers, function(marker){
						map.marker_cluster.markers.addLayer(marker);
					});

					//map.map.marker_cluster.markers.addLayer(marker);
					//show the markers for this category
				} else {

				}
			});
		});
	});
})(jQuery);
